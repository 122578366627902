@font-face {
    font-family: 'Noto Sans KR';
    font-style: normal;
    font-weight: 400;
    src: url("../../assets/fonts/noto-sans-kr/NotoSansKR-Regular.otf") format('opentype'),
    url("../../assets/fonts/noto-sans-kr/NotoSansKR-Regular.ttf") format('truetype'),
    url("../../assets/fonts/noto-sans-kr/NotoSansKR-Regular.woff") format('woff')
}

@font-face {
    font-family: 'Noto Sans KR Bold';
    font-style: normal;
    src: url("../../assets/fonts/noto-sans-kr/NotoSansKR-Bold.otf") format('opentype'),
    url("../../assets/fonts/noto-sans-kr/NotoSansKR-Bold.ttf") format('truetype'),
    url("../../assets/fonts/noto-sans-kr/NotoSansKR-Bold.woff") format('woff')
}

@font-face {
    font-family: 'Noto Sans KR Light';
    font-style: normal;
    src: url("../../assets/fonts/noto-sans-kr/NotoSansKR-Light.otf") format('opentype'),
    url("../../assets/fonts/noto-sans-kr/NotoSansKR-Light.ttf") format('truetype'),
    url("../../assets/fonts/noto-sans-kr/NotoSansKR-Light.woff") format('woff')
}

body, h1, h2, h3, h4, h5, h6, input, textarea, select, p {
    font-family: 'Noto Sans KR', sans-serif;
}
