.desktopRoot {
    /*background-color: #fafafa;*/
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 8px;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.containerRoot {
    display: flex !important;
    /*min-height: 80vh;*/
}

.boxRoot {
    width: inherit;
    height: inherit;
    text-align: left;
    margin-top: 50px !important;
}

.boardContainer {
    background-color: #fff;
    margin-top: -20px !important;
    padding: 32px;
    box-shadow: 0 4px 16px 0 rgba(5, 0, 56, 0.12);
}

.bodyBackground {
    background: linear-gradient(-90deg, #fafafa 30%, rgba(0, 0, 0, 0) 30%);
}

.boxShadow-1 {
    box-shadow: 0px 0px 10px lightgrey;
}

body {
    margin: 0;
    background-color: #ffffff;
}

.formContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80% !important;
    min-height: 70vh;
}

/* Filter Modal Styles */
.filterDialog .MuiDialog-paperScrollPaper {
    width: 600px;
}

.filterDialog .MuiDialogTitle-root {
    text-align: 'center';
}

.filterDialog .MuiFormControlLabel-labelPlacementStart {
    justify-content: space-between;
    margin-left: 0;
}

.filterDialog .MuiFormControlLabel-labelPlacementStart .MuiFormControlLabel-label {
    font-size: 14px;
}

.filterDialog .MuiDialogActions-root {
    justify-content: space-between;
}
/*  */


/* @media (min-width: 1281px) {
    .react-pdf__Page__canvas {
        width: 780px !important;
        height: auto !important;
    }
}

@media (min-width: 1025px) and (max-width: 1280px) {
    .react-pdf__Page__canvas {
        width: 780px !important;
        height: auto !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .react-pdf__Page__canvas {
        width: 780px !important;
        height: auto !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    .react-pdf__Page__canvas {
        width: 100% !important;
        height: auto !important;
        padding: 10px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .react-pdf__Page__canvas {
        width: 100% !important;
        height: auto !important;
        padding: 10px;
    }
}

@media (min-width: 320px) and (max-width: 480px) {
    .react-pdf__Page__canvas {
        width: 100% !important;
        height: auto !important;
        padding: 10px;
    }
} */
